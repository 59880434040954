.tooltip > * {
    margin-bottom: 16px;
}

.tooltip h2 {
    margin-bottom: 24px;
}

.tooltip li {
    margin-left: 24px;
}
