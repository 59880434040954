.modal {
    background-color: #fff;
    font-family: "Roboto", sans-serif;
    max-width: 1480px;
    margin: auto;
    height: 80vh;
    max-height: 80vh;
    border-radius: 3px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: auto;
    width: 80%;
    display: flex;
}

.modal > .left > h2 {
    color: rgba(0, 135, 183, 1);
    margin: 32px;
}

.modal > .left {
    background-color: rgba(223, 239, 244, 1);
    border-right: 1px solid rgba(0, 135, 183, 1);
    min-width: 400px;
    max-width: 400px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
}

.sideContent {
    overflow-y: scroll;
    overscroll-behavior: none;
    margin: 0px 20px 0px 32px;
    padding-right: 4px;
    flex: 1;
}

.tree {
    background-color: #f8fafb;
    border-top: 4px solid rgba(0, 135, 183, 1);
    border-radius: 3px;
    /* padding: 0 16px; */
    min-height: calc(100% - 4px);
}

.tree svg {
    font-size: 24px;
}

.action {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: 20px;
    padding: 20px 16px 0px 16px;
    border-top: solid 1px rgba(0, 135, 183, 1);
    box-shadow: 0px -8px 14px -4px #0000002e;
}

.modal > .right {
    padding: 32px;
    width: 100%;
}

.modal ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.modal ::-webkit-scrollbar-thumb {
    background: #93aab2;
    border-radius: 3px;
}

.modal ::-webkit-scrollbar-thumb:hover {
    background: #4f636d;
}

.header {
    display: flex;
    margin-bottom: 11px;
}

.header > .editableTitle {
    height: 50px;
}

.header > .editableTitle input {
    margin-top: 0px;
    width: 400px;
}

.header > .close {
    cursor: pointer;
    margin-left: auto;
    color: #4e636d;
}

.header > .close:hover {
    color: #000000;
}

.body {
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: calc(80vh - 185px);
    max-height: calc(80vh - 185px);
    scrollbar-color: #93aab2 transparent;
    scrollbar-width: thin;
    padding-right: 4px;
}

.question {
    color: #4e636d;
}

.filterName {
    cursor: pointer;
}

.filterName.selected {
    font-weight: bold;
}

.filterPart {
    position: relative;
    background-color: #f8fafb;
    padding: 24px;
    border-left: 4px solid rgba(0, 135, 183, 1);
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-radius: 3px;
    margin-bottom: 10px;
    display: flex;
}

.filterPart.invalidPart {
    border-top: 1px solid red;
    border-right: 1px solid red;
    border-bottom: 1px solid red;
    border-left-color: red;
}

.handle {
    margin: 10px;
    display: flex;
    align-items: center;
}

.message {
    display: flex;
    line-height: calc(80vh - 205px);
    overflow: hidden;
}

.message p {
    margin: auto;
}

.footer {
    margin-top: 32px;
    display: flex;
    justify-content: center;
}

.footer > * {
    margin: 0 5px;
}

.filterAsString {
    margin-left: 10px;
    margin-right: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.filterAsString > span {
    margin-left: 10px;
    margin-right: 10px;
}

.filterAsStringActions {
    margin-left: auto;
    display: flex;
    min-width: 50px;
    justify-content: space-between;
    align-items: center;
}

.filterAsStringActions > svg {
    font-size: 20px;
}

.filterEditor {
    width: calc(100% - 40px);
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.filterEditor .actions {
    margin-left: auto;
    margin-top: 16px;
}

.filterEditorLine {
    display: flex;
    margin-bottom: 10px;
}

.filterEditorLine:last-of-type {
    margin-bottom: 0px;
}

.filterEditorElement,
.filterEditorElementText {
    margin-left: 10px;
}

.filterEditorElement:first-of-type {
    margin-left: 0px;
}

.filterEditorElementText > div {
    display: flex;
    margin-top: 0;
}

.tabs {
    display: flex;
    margin-bottom: 20px;
}

.tab {
    flex: 1 !important;
    border-radius: 0 !important;
    border-left: none !important;
    justify-content: center !important;
    margin: 0 !important;
}

.active {
    background-color: #e2ebee !important;
    border-bottom: none !important;
}

.tabs > button:last-of-type {
    border-right: none !important;
}

.miniswatch {
    background-position: 0px 0px, 6px 6px;
    background-size: 12px 12px;
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),
        linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
    border-radius: 3px;
    box-shadow: 0 0 0 1px #93aab2;
    display: inline-block;
    flex: unset !important;
    margin-right: 10px;
}

.miniswatch > .color {
    width: 28px;
    height: 28px;
    border-radius: 3px;
}
