#portal-root {
    position: absolute;
    top: 0;
    left: 0;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.transparent {
    opacity: 0.5;
}

.__react_component_tooltip {
    z-index: 9999 !important;
}