.modal {
    background-color: #fff;
    font-family: "Roboto", sans-serif;
    max-width: 1480px;
    margin: auto;
    height: 80vh;
    max-height: 80vh;
    border-radius: 3px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
    width: 80%;
    display: flex;
}

.modal > .left > h2 {
    color: rgba(0, 135, 183, 1);
    margin: 32px;
}

.modal > .left {
    background-color: rgba(223, 239, 244, 1);
    border-right: 1px solid rgba(0, 135, 183, 1);
    min-width: 400px;
    max-width: 400px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
}

.sideContent {
    overflow-y: scroll;
    overscroll-behavior: none;
    margin: 0px 20px 0px 32px;
    padding-right: 4px;
    flex: 1;
}

.tree {
    background-color: #f8fafb;
    border-top: 4px solid rgba(0, 135, 183, 1);
    border-radius: 3px;
    /* padding: 0 16px; */
    min-height: calc(100% - 4px);
}

.tree svg {
    font-size: 24px;
}

.action {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: 20px;
    padding: 20px 16px 0px 16px;
    border-top: solid 1px rgba(0, 135, 183, 1);
    box-shadow: 0px -8px 14px -4px #0000002e;
}

.modal > .right {
    padding: 32px 32px 0 32px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.modal ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.modal ::-webkit-scrollbar-thumb {
    background: #93aab2;
    border-radius: 3px;
}

.modal ::-webkit-scrollbar-thumb:hover {
    background: #4f636d;
}

.header {
    display: flex;
    margin-bottom: 11px;
}

.header > .editableTitle {
    height: 50px;
}

.header > .editableTitle input {
    margin-top: 0px;
    width: 400px;
}

.header > .close {
    cursor: pointer;
    margin-left: auto;
    color: #4e636d;
}

.header > .close:hover {
    color: #000000;
}

.body {
    flex: 1;
    margin-bottom: 25px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-color: #93aab2 transparent;
    scrollbar-width: thin;
    padding-right: 4px;
}

.itemContainer {
    user-select: none;
}

.titleContainer {
    border-bottom: 1px solid #00000085;
    display: flex;
    padding: 9px 14px 8px 16px;
    background-color: #ffffff;
}

.title {
    vertical-align: middle;
    line-height: 22px;
    white-space: normal;
    padding-left: 6px;
    flex: 1;
    display: inline-flex;
    justify-content: space-between;
    cursor: pointer;
}

.titleContainer:hover {
    background-color: #f8f8f8;
}

.footer {
    display: flex;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.isDragging {
    opacity: 0.5;
}

.configurationName {
    cursor: pointer;
}

.configurationName.selected {
    font-weight: bold;
}

.topbar {
    display: flex;
    place-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.topbar input {
    margin: 0;
}

.topbar input[type="search"] {
    width: 100%;
}

/* @ui overrides */
.checkbox {
    margin-bottom: 0 !important;
}
