.color {
    width: 38px;
    height: 38px;
    border-radius: 3px;
}

.swatch {
    border-radius: 3px;
    box-shadow: 0 0 0 1px #93aab2;
    display: inline-block;
    flex: unset !important;
    margin-right: 50px;
    cursor: pointer;
    background-position: 0px 0px, 10px 10px;
    background-size: 20px 20px;
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),
        linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
}

.popover {
    position: absolute;
    z-index: 9050;
}

.picker {
    z-index: 1;
    position: fixed;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.container {
    width: 276px;
    background: rgb(255, 255, 255);
    border: 0px solid rgba(0, 0, 0, 0.25);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    border-radius: 4px;
    position: relative;
}

.container-arrow-one {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 9px 10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.1);
    position: absolute;
    top: -11px;
    left: 240px;
}

.container-arrow-two {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 9px 10px;
    border-color: transparent transparent rgb(255, 255, 255);
    position: absolute;
    top: -10px;
    left: 240px;
}

.container-arrow-three {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 9px 10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: -11px;
    left: 240px;
    transform: rotate(180deg);
}

.container-arrow-four {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 9px 10px;
    border-color: transparent transparent rgb(255, 255, 255);
    position: absolute;
    bottom: -10px;
    left: 240px;
    transform: rotate(180deg);
}

.container-arrow-five {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 9px 10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.1);
    position: absolute;
    right: -14px;
    top: calc(50% - 4px);
    transform: rotate(90deg);
}

.container-arrow-six {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 9px 10px;
    border-color: transparent transparent rgb(255, 255, 255);
    position: absolute;
    right: -13px;
    top: calc(50% - 4px);
    transform: rotate(90deg);
}

.content {
    padding: 12px;
}

.colortile {
    background: rgb(255, 105, 0);
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: relative;
    outline: none;
    float: left;
    border-radius: 4px;
    margin: 0px 6px 6px 0px;
}

.advanced {
    margin-top: 10px;
}

.saturation {
    width: 100%;
    padding-bottom: 55%;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.saturation > div {
    border-radius: 5px;
}

.body {
    padding-top: 16px;
}

.controls-container {
    display: flex;
}

.color-control {
    width: 32px;
}

.swatch-control {
    margin-top: 6px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.active-swatch {
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    z-index: 2;
    width: 16px;
    height: 16px;
}
.toggles {
    flex: 1;
}

.hue,
.alpha {
    height: 10px;
    position: relative;
    margin-bottom: 8px;
}

.hue > div > div,
.alpha > div > div {
    border-radius: 5px;
}
