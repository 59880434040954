.fixedWidthSelect {
    max-width: 290px !important;
}

.fixedWidthSelect > div > div {
    overflow: hidden;
}

.fixedWidthSelect > div > div > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: unset;
}
