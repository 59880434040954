.project-box {
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    max-width: 396px;
    min-width: 396px;
    min-height: 386px;
    margin: 12px;
    display: flex;
    flex-direction: column;
}

.project-box.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.project-box-header {
    margin: 28px 24px 13px 24px;
}

.project-box .content_line {
    margin: 0 24px;
}

.project-box-content p {
    margin: 0 24px 24px 24px;
}

.project-box-create {
    display: flex;
    flex: 1;
    margin: auto;
}

.project-box-create p {
    align-self: center;
    cursor: pointer;
}

.project-box-image {
    margin-top: auto;
}

.project-box-footer {
    display: flex;
    margin: 8px 24px;
}
.project-box-create-footer {
    display: flex;
    margin: 8px auto;
}

.project-box-footer .button:last-of-type {
    margin-left: auto;
}

#viewer-header {
    margin: 20px auto;
    max-width: 1680px;
    display: flex;
    flex-wrap: wrap;
}

#viewer-header > h1 {
    color: #4f636d;
}

#viewer-header .tag {
    background-color: #c45b00;
}

#viewer-header .tag.shared {
    background-color: rgba(0, 135, 183, 1);
}

#viewer-header #action-buttons {
    display: flex;
    margin-left: auto;
}

#viewer-header #action-buttons svg {
    font-size: 24px;
}

#viewer-header #action-buttons .vertical-spacer {
    border-right: 1px solid #93aab2;
    margin-right: 15px;
}

#viewer-page {
    height: calc(100vh - 140px);
    max-height: calc(100vh - 140px);
    max-width: calc(100vw - 48px);
    margin: 0 auto;
    padding-bottom: 30px;
}

#viewer-content {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 135, 183, 1);
    border-radius: 5px;
}

#viewer-content #sidebar {
    background-color: rgba(223, 239, 244, 1);
    border-right: 1px solid rgba(0, 135, 183, 1);
    min-width: 420px;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

#viewer-content #sidebar #tabs {
    display: flex;
}

#viewer-content #sidebar svg {
    font-size: 20px;
    vertical-align: middle;
}

#viewer-content #sidebar #tabs .button {
    border-radius: 0;
    border-top: none;
    border-left: none;
}

#viewer-content #sidebar #tabs .button.active {
    flex: 1;
    background-color: rgba(223, 239, 244, 1);
    border-bottom: none;
}

#viewer-content #sidebar #tabs .button.active > svg {
    margin-right: 14px;
}

#viewer-content #sidebar #tabs .button:first-of-type {
    border-top-left-radius: 5px;
}

#viewer-content #sidebar #tabs .button:last-of-type {
    border-right: none;
}

#viewer-content #sidebar #tab-header {
    margin: 16px;
}

#viewer-content #sidebar #tab-header.model-tree {
    display: flex;
    line-height: 40px;
}

#viewer-content #sidebar #tab-header.filters {
    display: flex;
}

#viewer-content #sidebar #tab-header.model-tree .button {
    /* padding: 0 7px; */
    margin-left: auto;
}

#viewer-content #sidebar #tab-header.filters .button {
    margin: auto 0 auto 20px;
    white-space: nowrap;
}

#viewer-content #sidebar #tab-subtabs {
    display: flex;
}

#viewer-content #sidebar #tab-subtabs .button {
    flex: 1;
    border-radius: 0;
    border-left: none;
    justify-content: center;
}

#viewer-content #sidebar #tab-subtabs .button.active {
    background-color: rgba(223, 239, 244, 1);
    border-bottom: none;
}

#viewer-content #sidebar #tab-subtabs .button:last-of-type {
    border-right: none;
}

#viewer-content #sidebar #tab-content-filters {
    display: flex;
    margin: 0 16px;
}
#viewer-content #sidebar #tab-content-filters > button {
    margin-left: auto;
}

#viewer-content #sidebar #tab-content-search {
    margin: 16px;
}

#viewer-content #sidebar #tab-content-search > div > input,
#property-viewer-searchbox > div > input {
    width: calc(100% - 2px);
    max-width: unset;
}

#viewer-content #sidebar #tab-content-filter-selection {
    margin: 0 16px 8px 16px;
}

#viewer-content #sidebar #tab-content-filter-selection.disabled {
    opacity: 0.5;
    pointer-events: none;
}

#viewer-content #sidebar #tab-content {
    overflow-y: scroll;
    overscroll-behavior: none;
    margin: 0px 4px 0px 16px;
    padding-right: 4px;
    flex: 1;
}

#viewer-content #sidebar #tab-content.no-header,
#viewer-content #sidebar #tab-content-filters.no-header,
#viewer-content #sidebar #tab-content-search.no-header {
    margin-top: 16px;
}

#viewer-content #sidebar #tab-content .infobox,
.poper_tab_content .infobox,
#sections-viewer-content .infobox {
    border-left: 4px solid #93aab2;
    border-radius: 3px;
    background-color: #f8fafb;
    padding: 8px 12px;
    margin-bottom: 20px;
    color: #4e636d;
}

#viewer-content #sidebar #tab-content .infobox.active,
.poper_tab_content .infobox.active,
#sections-viewer-content .infobox.active {
    border-left: 4px solid rgba(0, 135, 183, 1);
}

#viewer-content #sidebar #tab-content .infobox.failed,
.poper_tab_content .infobox.failed,
#sections-viewer-content .infobox.failed {
    border: 1px solid #e60b0b;
    border-left: 4px solid #e60b0b;
}

#viewer-content #sidebar #tab-content .infobox .infobox-header,
.poper_tab_content .infobox .infobox-header,
#sections-viewer-content .infobox .infobox-header {
    display: flex;
}

#viewer-content #sidebar #tab-content .infobox .infobox-header h3,
.poper_tab_content .infobox .infobox-header h3,
#sections-viewer-content .infobox .infobox-header h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#viewer-content #sidebar #tab-content .infobox .infobox-actions,
.poper_tab_content .infobox .infobox-actions,
#sections-viewer-content .infobox .infobox-actions {
    display: flex;
    justify-content: space-between;
    min-width: 110px;
    margin-left: auto;
    color: #4f636d;
}

#viewer-content #sidebar #tab-content .infobox .infobox-actions > svg {
    margin-left: auto;
}

#viewer-content #sidebar #tab-content .infobox .infobox-actions .action-button:hover,
.poper_tab_content .infobox .infobox-actions .action-button:hover,
#sections-viewer-content .infobox .infobox-actions .action-button:hover {
    color: #93aab2;
    cursor: pointer;
}

#viewer-content #sidebar #tab-content .infobox .activation-toggle,
.poper_tab_content .infobox .activation-toggle,
#sections-viewer-content .infobox .activation-toggle {
    background-color: #93aab2;
    border-radius: 3px;
    color: #ffffff;
    padding: 4px 8px;
    display: inline-block;
    width: auto;
    margin: 8px 0;
}

#viewer-content #sidebar #tab-content .infobox .activation-toggle.active,
.poper_tab_content .infobox .activation-toggle.active,
#sections-viewer-content .infobox .activation-toggle.active {
    background-color: rgba(0, 135, 183, 1);
}

#viewer-content #sidebar #tab-content .infobox .activation-toggle > label,
#sections-viewer-content .infobox .activation-toggle > label {
    margin-bottom: 0;
}

#viewer-content #sidebar #tab-content .infobox .activation-toggle .checkbox-container,
#viewer-content #sidebar #tab-content .infobox .activation-toggle .radio-container,
.poper_tab_content .infobox .activation-toggle .checkbox-container,
#sections-viewer-content .infobox .activation-toggle .checkbox-container {
    margin: 0;
    font-size: 14px;
}

#viewer-content #sidebar #tab-content .infobox .activation-toggle .checkbox-indicator,
.poper_tab_content .infobox .activation-toggle .checkbox-indicator,
#sections-viewer-content .infobox .activation-toggle .checkbox-indicator {
    height: 16px;
    width: 16px;
}

#viewer-content #sidebar #tab-content .infobox .activation-toggle .checkbox-indicator:after,
.poper_tab_content .infobox .activation-toggle .checkbox-indicator:after,
#sections-viewer-content .infobox .activation-toggle .checkbox-indicator:after {
    font-size: 11px;
}

#viewer-content #sidebar #tab-content .infobox .activation-toggle .checkbox-container .checkbox-indicator:after,
.poper_tab_content .infobox .activation-toggle .checkbox-container .checkbox-indicator:after,
#sections-viewer-content .infobox .activation-toggle .checkbox-container .checkbox-indicator:after {
    width: 16px;
    height: 16px;
}

#viewer-content #sidebar #tab-content .infobox .model-actions,
.poper_tab_content .infobox .model-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

#viewer-content #sidebar #tab-content .infobox .model-actions svg,
.poper_tab_content .infobox .model-actions svg {
    font-size: 16px;
}

#viewer-content #sidebar #tab-content .infobox .model-actions.disabled,
.poper_tab_content .infobox .model-actions.disabled {
    pointer-events: none;
    opacity: 0.5;
}

#viewer-content #sidebar #tab-content .model-tree {
    overflow: unset !important;
    overflow-y: scroll !important;
}

#viewer-content #sidebar #tab-content .model-tree > div {
    background-color: #f8fafb;
    margin-right: 8px;
    min-height: 100%;
    width: unset !important;
}

#viewer-content #sidebar #tab-content #model-tree-top {
    background-color: #f8fafb;
    border-top: 4px solid rgba(0, 135, 183, 1);
    border-radius: 3px 3px 0 0;
    width: calc(100% - 10px);
}

#viewer-content #sidebar #tab-content .model-tree .ifcviewer-tree-item {
    cursor: pointer;
}

#viewer-content #sidebar #tab-content .model-tree .bold-tree-item {
    font-weight: bold;
}

#viewer-content #sidebar #tab-content .model-tree .tree-item {
    max-width: calc(100% - 8px) !important;
    padding: 5px 0;
}

#viewer-content #sidebar #tab-content .model-tree .tree-item-value,
#viewer-content #sidebar #tab-content .model-tree .tree-item-value > div {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    vertical-align: middle;
}

#viewer-content #sidebar #tab-content .model-tree .tree-item-actions {
    min-width: 65px;
    margin-right: 2px;
}

#viewer-content #sidebar #bottom-menu {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: 20px;
    padding: 20px 16px 0px 16px;
    border-top: solid 1px rgba(0, 135, 183, 1);
    box-shadow: 0px -8px 14px -4px #0000002e;
}

#viewer-content #sidebar ::-webkit-scrollbar-thumb,
#property-viewer ::-webkit-scrollbar-thumb,
#tv-search ::-webkit-scrollbar-thumb {
    background: #93aab2;
    border-radius: 5px;
}

#viewer-content #sidebar-control {
    border-radius: 0 20px 20px 0;
    margin-top: 10px;
    padding: 0 0 0 10px;
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 0;
}

#viewer-content #viewer {
    position: relative;
    width: 100%;
    height: 100%;
}

#viewer-content #viewer #viewer-canvas {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 5px;
}

#viewer-content #viewer #viewer-navcube-canvas {
    width: 200px;
    height: 200px;
    z-index: 8;
}

#viewer-content #view-info {
    display: flex;
    border-bottom: 1px solid #93aab2;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 1;
    justify-content: flex-end;
    align-items: center;
}

#viewer-content #view-info.no-line {
    border-bottom: none;
}

#view-info h2 {
    text-shadow: #ffffff90 1px 1px 0px;
}

#viewer-content #view-info .tag {
    background-color: #1bb9c5;
    margin-top: 0;
}

#viewer-content #actionbars {
    justify-content: center;
    display: flex;
    z-index: 1;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    align-self: self-end;
    height: fit-content;
    flex: 1;
}

#viewer-content #actionbars > #actionbar-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: inherit;
}

#viewer-content .actionbar {
    border-radius: 5px;
    border: 1px solid rgba(0, 135, 183, 1);
    color: rgba(0, 135, 183, 1);
    justify-content: space-around;
    background-color: #ffffff;
    display: flex;
    z-index: 1;
    margin: 0 5px 20px;
    box-shadow: 1px 3px 10px 0 rgb(0 0 0 / 50%);
}

#viewer-content .actionbar .actionbar-button {
    cursor: pointer;
    line-height: 12px;
    width: 64px;
    border-right: 1px solid rgba(0, 135, 183, 1);
}

#viewer-content .actionbar button {
    padding: 5px 20px;
    border: none;
    color: rgba(0, 135, 183, 1);
    background-color: transparent;
    cursor: pointer;
}

#viewer-content .actionbar button .actionbar-group,
#viewer-content .actionbar button span,
#viewer-content .actionbar button svg,
#viewer-content .actionbar button svg * {
    pointer-events: none;
}

#viewer-content .actionbar button > * ul,
#viewer-content .actionbar button > * ul > li {
    pointer-events: all;
}

#viewer-content .actionbar button > * ul > li * {
    pointer-events: none;
}

#viewer-content .actionbar .actionbar-button:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

#viewer-content .actionbar .actionbar-button:last-of-type {
    border-right: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

#viewer-content .actionbar .actionbar-button:hover {
    background-color: rgba(223, 239, 244, 1);
}

#viewer-content .actionbar button svg {
    font-size: 24px;
}

#viewer-content .actionbar .actionbar-group {
    position: relative;
}

#viewer-content .actionbar .actionbar-group-buttons {
    position: absolute;
    bottom: 40px;
    left: 0px;
    transform: translate(-50%, 0);
    border: 1px solid rgba(0, 135, 183, 1);
    border-radius: 3px;
    text-align: left;
    white-space: nowrap;
}

#viewer-content .actionbar .actionbar-group-buttons span {
    display: block;
    background-color: rgba(223, 239, 244, 1);
    border-bottom: 1px solid rgba(0, 135, 183, 1);
    padding: 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

#viewer-content .actionbar .actionbar-group-buttons ul {
    list-style: none;
}

#viewer-content .actionbar .actionbar-group-buttons ul li {
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 135, 183, 1);
    background-color: #ffffff;
    padding: 10px;
}

#viewer-content .actionbar .actionbar-group-buttons ul li svg {
    font-size: 18px;
    width: 28px;
}
#viewer-content .actionbar .actionbar-group[data-count]:after {
    position: absolute;
    display: flex;
    place-content: center;
    right: -10px;
    top: -4px;
    content: attr(data-count);
    font-size: 12px;
    padding: 3px;
    border-radius: 50%;
    color: white;
    background: rgba(0, 135, 183, 0.9);
    text-align: center;
    min-width: 14px;
    font-weight: bold;
}

#viewer-content .actionbar .actionbar-group-buttons ul li:hover {
    background-color: rgba(0, 135, 183, 1);
    color: #ffffff;
}

#viewer-content .actionbar .actionbar-group-buttons ul li:last-of-type {
    border-bottom: none;
}

#property-viewer,
#sections-viewer,
#tv-search {
    position: absolute;
    color: #4e636d;
    border: 1px solid rgba(0, 135, 183, 1);
    background-color: rgba(223, 239, 244, 1);
    z-index: 15;
    border-radius: 3px;
    min-width: 375px;
    min-height: 200px;
}

#property-viewer,
#tv-search {
    display: flex;
    flex-direction: column;
}

#property-viewer-header,
#sections-viewer-header,
#tv-search-header {
    display: flex;
    line-height: 28px;
    cursor: move;
    align-items: center;
}

#property-viewer-header > h2,
#sections-viewer-header > h2,
#tv-search-header > h2 {
    margin: 16px 0;
}

#property-viewer-header > svg,
#sections-viewer-header > svg,
#tv-search-header > svg {
    margin: 16px;
    font-size: 22px;
}

#property-viewer-header > svg.close-button,
#sections-viewer-header > svg.close-button,
#tv-search-header > svg.close-button {
    margin: 16px;
    margin-left: auto;
    cursor: pointer;
}

#property-viewer-searchbox {
    margin: 0 16px 16px 16px;
}

#property-viewer-content,
#sections-viewer-content,
#tv-search-content {
    width: calc(100% - 24px);
    height: calc(100% - 142px);
    overflow-y: scroll;
    overscroll-behavior: none;
    margin: 0px 10px 0px 16px;
    padding-right: 4px;
}

#property-viewer-content {
    height: calc(100% - 197px);
}

#tv-search-content {
    height: calc(100% - 54px);
}

#property-viewer-tabs {
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

#property-viewer-tabs .button {
    flex: 1;
    border-radius: 0;
    border-left: none;
    justify-content: center;
    min-width: fit-content;
}

#property-viewer-tabs .button.active {
    background-color: rgba(223, 239, 244, 1);
    border-bottom: none;
}

#property-viewer-tabs .button:last-of-type {
    border-right: none;
}

#sections-viewer-content {
    height: calc(100% - 76px);
}

.property-set {
    border-top: 4px solid rgba(0, 135, 183, 1);
    background-color: #f8fafb;
    margin-bottom: 16px;
    border-radius: 3px;
}

.property-set-header {
    display: flex;
    line-height: 24px;
    padding: 16px 0;
    margin: 0 16px;
    align-items: center;
}

.property-set-header > svg {
    margin-left: auto;
    font-size: 22px;
    cursor: pointer;
}

.property-set-header.expanded {
    border-bottom: 2px solid #93aab2;
}

.property-set-data {
    margin: 12px 24px;
}

.property-set-data > p {
    padding-bottom: 12px;
}

.property-set-data > p > strong {
    display: block;
}

#shared-projects .poper_content {
    max-height: calc(62vh - 340px);
    overflow-y: scroll;
}

.shared-project {
    border-left: 6px solid rgba(0, 135, 183, 1);
    border-radius: 3px;
    background-color: #f8fafb;
    padding: 22px 16px 16px 16px;
    margin-bottom: 16px;
    color: #4f636d;
}

.shared-project .custom-dropdown {
    max-width: unset;
}

.shared-project-header {
    display: flex;
}

.shared-project-header p {
    font-size: 20px;
}

.shared-project-header button {
    margin-left: auto;
}

.shared-project-header button svg {
    font-size: 24px;
    margin-right: 10px;
}

#share-project .poper_input textarea {
    min-height: unset;
}

#share-project .custom-dropdown {
    margin-left: 32px;
}

#viewer-configuration {
    max-width: 600px;
    max-height: 50vh;
}

#viewer-configuration .poper_tab_content {
    max-height: calc(50vh - 300px);
}

#viewer-configuration #viewer-settings {
    padding: 16px;
}

#filter-management,
#view-management {
    max-width: 840px;
    max-height: 80vh;
}

#view-management .checkmark {
    pointer-events: none;
    opacity: 0.5;
}

#view-management .tree-item:hover {
    background-color: unset;
}

.poper_tabbed_content {
    background-color: rgba(223, 239, 244, 1);
    border: 1px solid rgba(0, 135, 183, 1);
    border-radius: 5px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: 32px 0;
}

.poper_tabbed_content .poper_tabs {
    display: flex;
}

.poper_tabbed_content .poper_tabs .button {
    flex: 1;
    border-radius: 0;
    border-top: none;
    border-left: none;
    place-content: center;
}

.poper_tabbed_content .poper_tabs .button.active {
    background-color: rgba(223, 239, 244, 1);
    border-bottom: none;
}

.poper_tabbed_content .poper_tabs .button:first-of-type {
    border-top-left-radius: 5px;
}

.poper_tabbed_content .poper_tabs .button:last-of-type {
    border-right: none;
    border-top-right-radius: 5px;
}

.poper_tab_content {
    height: calc(80vh - 300px);
    display: flex;
}

.poper_tab_content #filters-list {
    flex: 0 1 calc(50%);
    display: flex;
    flex-direction: column;
    padding-top: 16px;
}

.poper_tab_content #filters-list-scrollable {
    overflow-y: scroll;
    margin: 0 4px 0 16px;
    padding-right: 4px;
    max-height: 100%;
}

.poper_tab_content #ifc-class-tree-container {
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(0, 135, 183, 1);
}

.poper_tab_content #ifc-class-tree-scrollable {
    overflow-y: scroll;
    margin: 16px 4px 0 16px;
    padding-right: 4px;
}

.poper_tab_content #ifc-class-tree {
    border-top: 4px solid rgba(0, 135, 183, 1);
    border-radius: 3px;
    background-color: #f8fafb;
}

.poper_tab_content #ifc-class-tree .tree-item-actions {
    max-width: 5px;
    min-width: 5px;
}

#ifc-class-tree-container #filter-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid rgba(0, 135, 183, 1);
    padding: 16px;
}

#ifc-class-tree-container #filter-actions svg {
    font-size: 16px;
}

#ifc-class-tree-container #filter-actions.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.poper_tab_content #models-list {
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    margin: 16px;
}

.tree-item-actions .disabled {
    pointer-events: none;
    opacity: 0.5;
}

.tag {
    margin-top: 7px;
    padding: 2px 6px;
    font-size: 12px;
    color: #ffffff;
    border-radius: 3px;
    width: fit-content;
}

.button {
    border: none;
    font-family: "Roboto";
    border-radius: 20px;
    background-color: #ffffff;
    line-height: 40px;
    font-size: 16px;
    display: flex;
    padding: 0 20px;
    font-weight: bold;
    height: 40px;
    cursor: pointer;
}

.button.primary {
    background-color: #e98b3a;
    color: white;
}

.button.primary:hover {
    background-color: #f7931d;
}

.button.secondary {
    border: 1px solid rgba(0, 135, 183, 1);
    color: rgba(0, 135, 183, 1);
}

.button.secondary:hover {
    background-color: rgba(223, 239, 244, 1);
}

.button.tertiary {
    color: #4e636d;
    background-color: transparent;
}

.button.tertiary:hover {
    background-color: rgba(223, 239, 244, 1);
}

.button > svg {
    align-self: center;
    font-size: 22px;
}

.checkbox-container,
.radio-container {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-container input,
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid rgba(0, 135, 183, 1);
}

.checkbox-container .checkbox-indicator {
    border-radius: 4px;
}

.radio-container .checkbox-indicator {
    border-radius: 50%;
}

.checkbox-indicator:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container .checkbox-indicator:after {
    content: "\2713";
    font-size: 14px;
}

.checkbox-container input:checked ~ .checkbox-indicator:after,
.radio-container input:checked ~ .checkbox-indicator:after {
    display: block;
}

.checkbox-indicator:after {
    background: rgba(0, 135, 183, 1);
    text-align: center;
    color: white;
}

.checkbox-container .checkbox-indicator:after {
    width: 18px;
    height: 18px;
    border-radius: 2px;
}

.radio-container .checkbox-indicator:after {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 3px;
    left: 3px;
}

.checkbox-container input:indeterminate ~ .checkbox-indicator {
    display: block;
    background: #85bdbe;
}

.checkbox-container.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.poper_input input.error,
.poper_input input.input-invalid {
    border: 1px solid #e02020 !important;
}

.poper_input .validation-error {
    color: #e02020;
    display: block;
    width: fit-content;
    margin-top: 4px;
}

.progress-bar-wrapper {
    width: 500px;
}

.progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    padding: 3px;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.progress-bar-fill {
    display: block;
    height: 30px;
    background-color: #659cef;
    border-radius: 3px;

    transition: width 500ms ease-in-out;
}

@media (max-width: 990px) and (min-width: 320px) {
    h2 {
        margin: 0 20px;
    }

    .project-box {
        min-width: unset;
    }

    .project-box h2 {
        margin: unset;
    }

    .project-box .content_line .line_group {
        padding-left: 0;
    }

    .project-box-create > p {
        margin-left: 68px;
        margin-right: 68px;
    }
}

#camera-pivot-marker {
    width: 25px;
    height: 25px;
    background-color: rgba(0, 135, 183, 1);
    border-radius: 50%;
    position: absolute;
    z-index: 99;
    pointer-events: none;
    visibility: hidden;
}

#filterTree .tree-item-actions {
    min-width: 80px;
}
#filterTree .tree-item-expander {
    width: 0;
    min-width: 0;
}

/* CSS Override for react-tooltip to hide the arrow */
.__react_component_tooltip::before,
.__react_component_tooltip::after {
    content: none !important;
}

.view-infobox {
    display: flex;
}

.infobox-group {
    width: calc(100% - 94px);
}

.view-img {
    width: 94px;
    margin-right: 8px;
    background-position: center top !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border-radius: 4px;
}

.material-table {
    border-collapse: collapse;
    margin: 25px 0;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.material-table thead tr {
    background-color: rgba(0, 135, 183, 1);
    color: #ffffff;
    text-align: left;
}

.material-table th,
.material-table td {
    padding: 12px 15px;
}
.material-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.material-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.material-table tbody tr:last-of-type {
    border-bottom: 2px solid rgba(0, 135, 183, 1);
}

.material-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

#viewer-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.chips {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: -5px;
    line-height: 28px;
}

.chip {
    display: flex;
    background-color: #93aab2;
    color: #fff;
    padding: 0 8px;
    border-radius: 3px;
    margin: 5px;
    user-select: none;
}

.chip > svg {
    margin: 6px 0 0 8px;
    cursor: pointer;
    color: rgb(230, 230, 230);
}

.chip > svg:hover {
    color: #fff;
}

.tree-item-tooltip {
    font-size: 14px !important;
    pointer-events: auto !important;
}

.tree-item-tooltip:hover {
    visibility: visible !important;
    opacity: 1 !important;
}

.keybind-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.keybind-column {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.keybind {
    margin: 0px !important;
    height: 50px;
    display: flex;
    align-items: center;
    min-width: 35px;
}

.keybind > kbd {
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 2px 0 0 rgb(255 255 255 / 70%) inset;
    color: #333;
    display: inline-block;
    font-size: 1.85em;
    line-height: 1;
    padding: 4px 8px;
    white-space: nowrap;
    font-family: monospace;
}

.keybind > kbd:not(:first-of-type) {
    margin-left: 10px;
}

.keybind > .key-separator:before {
    content: "/";
}

.keybind > .image-container {
    min-width: 32px;
    display: flex;
}

.keybind > .image-container > img {
    height: 30px;
    margin: auto;
}

.keybind > .info-image-container {
    margin-left: 10px;
    display: flex;
    width: 50px;
    place-content: center;
}

.keybind > .info-image-container > img {
    height: 45px;
}

.keybind > span {
    margin-left: 10px;
}


.new-tutorial{
    width: 10px;
    height: 10px;
    background: orange;
    position: absolute;
    left: 0;
    bottom: 0px;
    border-radius: 10px;
}

.new-tutorial:before{
    content: "";
    width: 10px;
    height: 10px;
    background: orange;
    position: absolute;
    left: 0;
    bottom: 0px;
    border-radius: 10px;
    animation: pulse 1s infinite;
}

@keyframes pulse{
    0%{
        transform: scale(1);
        opacity: 1;
    }
    100%{
        transform: scale(2);
        opacity: 0;
    }
}
