/* a {
    color: rgba(0, 135, 183, 0.8);
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: rgba(0, 135, 183, 1);
    text-decoration: underline;
} */

/* * {
    padding: 0;
    margin: 0;
    font-family: "Roboto", "robotoregular";
} */
/* body {
    background-color: #f8fafb;
} */
/* #root {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
} */

input {
    -webkit-appearance: none;
}

.noscroll {
    overflow: hidden;
}

.noexportscroll {
    overflow: hidden !important;
}

/*responsive on large screens #root max-width: 100%;*/

/* Content */
h1 {
    color: #4e636d;
}
h2 {
    color: #4e636d;
}
h3 {
    color: #4e636d;
}
h4 {
    font-size: 22px;
}
input,
button,
textarea {
    outline: none;
}

.btn_pri {
    background-color: #e98b3a;
    color: white;
    border: none;
    border-radius: 20px;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
    display: flex;
    padding: 0 20px;
    height: 40px;
    cursor: pointer;
    white-space: nowrap;
}
.btn_pri:hover {
    background-color: #f7931d;
}
.btn_pri > svg {
    align-self: center;
}
.btn_sec {
    border: 1px solid rgba(0, 135, 183, 1);
    border-radius: 20px;
    background-color: #ffffff;
    line-height: 24px;
    font-size: 16px;
    display: flex;
    color: rgba(0, 135, 183, 1);
    padding: 0 20px;
    font-weight: bold;
    height: 40px;
    cursor: pointer;
    line-height: 38px;
    white-space: nowrap;
}
.btn_sec:hover {
    background-color: #e2ebee;
}
.btn_sec > svg {
    align-self: center;
}
.btn_tri {
    color: #4e636d;
    border: none;
    font-size: 16px;
    display: flex;
    line-height: 24px;
    padding: 0 20px;
    background-color: transparent;
    font-weight: bold;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    white-space: nowrap;
}
.btn_tri:hover {
    border-radius: 20px;
    background-color: #e2ebee;
}
.btn_tri > svg {
    align-self: center;
}

.apps-link {
    display: flex;
    color: #008685;
    text-decoration: none;
}
.apps-link:hover {
    color: #008685;
    text-decoration: none;
}

.main_header,
.top_header {
    background-color: #f8fafb;
    padding-top: 20px;
}
.main_header .dropdown,
.top_header .dropdown {
    font-size: 18px;
    line-height: 38px;
    display: flex;
    color: #008685;
}
.main_header .dropdown-content,
.top_header .dropdown-content {
    top: 40px;
}

.top_header_btns {
    display: flex;
    margin-left: auto;
    padding-top: 22px;
}
.top_header_btns > .dropdown + .dropdown {
    margin-left: 23px;
}
.sub_header {
    background-color: white;
    color: #008685;
}
.sub_header_btns {
    display: flex;
    margin-left: auto;
}
.sub_header_btns .dropdown-content {
    top: 65px !important;
}

.sub_hamburger {
    padding-top: 27px;
    margin-left: auto;
}

.header {
    display: flex;
    background-color: #f1f6f6;
    display: flex;
    box-shadow: 0px 2px 14px -4px #0000002e;
    padding: 18px 40px;
    margin-bottom: 40px;
}
.header_icon {
    height: 38px;
    width: 26px;
    padding-left: 20px;
    color: #008685;
    margin-top: 14px;
}
.insider {
    display: flex;
    width: 86%;
    background-color: white;
    margin-left: auto;
    padding: 12px 26px;
    border-radius: 4px;
    color: #008685;
}

.insider_title {
    font-size: 24px;
    font-weight: bold;
    width: 260px;
    line-height: 90px;
    display: flex;
}
.insider_title img {
    height: 40px;
    margin-right: 10px;
    padding: 25px 0;
}
.insider_btns {
    display: flex;
    margin-left: auto;
    width: 780px;
    justify-content: flex-end;
}
.insider_btn {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    margin-right: 20px;
    line-height: 38px;
    cursor: pointer;
    padding-top: 26px;
}

.insider_btn > svg {
    color: #008685;
    width: 23px;
    height: 38px;
    margin-right: 4px;
}
.mini_icon {
    color: #008685;
    width: 23px;
    height: 38px;
    margin-right: 4px;
}

.insider_btn:last-child {
    margin-right: 0;
}

.breadcrumb_wrapper {
    max-width: 1680px;
    position: absolute;
    margin-left: -840px;
    left: 50%;
    top: 88px;
}
.breadcrumb {
    display: flex;
    background-color: white;
    padding: 2px 8px;
    font-size: 12px;
    width: fit-content;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 #00000017;
    line-height: 10px;
    align-items: center;
}

.bread_icon {
    color: #4e636d;
    width: 14px;
    height: 18px;
}
.bread_link {
    color: #4e636d;
    line-height: 18px;
    cursor: pointer;
    text-decoration: none;
}
.bread_link::before {
    padding-left: 5px;
    content: "> ";
    line-height: 18px;
    vertical-align: top;
}

.content {
    margin: 40px auto 0;
    max-width: 1680px;
}
.content_header {
    display: flex;
    margin-bottom: 20px;
}
.content_info {
    display: flex;
    color: #4e636d;
    margin-bottom: 30px;
}
.content_btns {
    margin-left: auto;
    display: flex;
}
.content_bottom {
    display: flex;
    margin-bottom: 60px;
    padding-top: 18px;
    border-top: solid 1px #008685;
}
.descBox {
    width: 50%;
    padding-right: 5%;
}
.dateBox {
    width: 30%;
    text-align: right;
    margin-left: auto;
}
.dateBox ul li {
    text-align: left;
}
.linkBox {
    width: 25%;
    margin-left: auto;
}
.linkBox ul {
    list-style: none;
    text-align: right;
}
.linkBox a {
    color: #00a79d;
}

.content_line {
    border-top: solid 1px #008685;
    margin-bottom: 10px;
}

.bluee {
    background-color: #cffcff;
    padding: 2px 6px;
    font-size: 12px;
    color: #4e636d;
}
.orange {
    background-color: #ffe6cf;
    padding: 2px 6px;
    font-size: 12px;
    color: #4e636d;
}

.white_box {
    background-color: white;
    box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;
}

.search_box {
    border: solid 1px #a7bbc1;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: white;
    display: flex;
    width: 300px;
    margin-left: auto;
    height: 28px;
}
.search_icon {
    color: #008685;
    height: 30px;
}
.search_closer {
    color: #4e636d;
}
.search_input {
    color: #008685;
    border: none;
    width: 100%;
    padding-left: 5px;
    font-size: 16px;
}
.search_input:active {
    border: none;
}
.search_input::placeholder {
    color: #7cc0c0;
}

.check {
    min-width: 32px !important;
    padding: 15px 0 !important;
    background-color: white !important;
    height: 23px;
    position: absolute;
}

.prj {
    color: #008685;
    padding-left: 10px;
    width: 40%;
    display: flex;
}
.prj_icon {
    padding: 5px 5px 0 0;
}
.prj_name {
    padding-left: 40px;
    width: 38%;
}
.dt {
    width: 20%;
}
.act {
    display: flex;
    width: 20%;
}
.act button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    color: #4e636d;
    margin-right: 20px;
    line-height: 16px;
    min-width: 14px;
    overflow: hidden;
    align-self: center;
}
.act_icon {
    height: 16px;
    width: 14px;
    margin-right: 5px;
    min-height: 16px;
    min-width: 14px;
}
.act_text {
    display: initial;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    z-index: 1;
    min-width: 230px;
    border: solid 1px #008685;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    right: 0px;
    white-space: nowrap;
}
.dropdown-content ul {
    list-style: none;
}
.dropdown-content ul li {
    border-bottom: solid 1px #008685;
    padding-right: 20px;
}
.dropdown-content ul li:hover {
    background-color: #008685;
    color: white;
    cursor: pointer;
}
.dropdown-content ul li:hover a,
.dropdown-content ul li:hover span,
.dropdown-content ul li:hover a .mini_icon {
    color: white;
}
.dropdown-content ul li:last-child {
    border-bottom: none;
}
.dropdown-content ul li a {
    display: flex;
    color: #008685;
    text-decoration: none;
    font-weight: bold;
    line-height: 38px;
    padding-left: 16px;
    font-size: 14px;
}
.dropdown-content ul li span {
    display: flex;
    color: #008685;
    text-decoration: none;
    font-weight: bold;
    line-height: 38px;
    padding-left: 16px;
    font-size: 14px;
}

.dropdown-content-orange {
    display: none;
    position: absolute;
    z-index: 1;
    min-width: 337px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    margin-left: 10px;
    white-space: nowrap;
}
.dropdown-content-orange ul {
    list-style: none;
    border: solid 1px #e98b3a;
    border-radius: 3px;
    background-color: white;
    margin-top: 5px;
}
.dropdown-content-orange ul li {
    border-bottom: solid 1px #e98b3a;
    padding-right: 20px;
}
.dropdown-content-orange ul li:hover {
    background-color: #e98b3a;
}
.dropdown-content-orange ul li:hover a {
    color: white;
}
.dropdown-content-orange ul li:last-child {
    border-bottom: none;
}
.dropdown-content-orange ul li a {
    display: flex;
    color: #e98b3a;
    text-decoration: none;
    font-weight: bold;
    line-height: 38px;
    padding-left: 20px;
    font-size: 14px;
}
.dropdown-content-orange ul li a svg {
    margin-right: 5px;
    margin-top: 8px;
}
.dropdown-content p {
    color: #008685;
    font-weight: bold;
    line-height: 38px;
    padding-left: 16px;
    border-top: solid 1px #008685;
}

.dropdown:hover .dropdown-content {
    display: block;
}
.dropdown:hover .dropdown-content-orange {
    display: block;
}

.mobile_menu .dropdown {
    display: block;
    margin-left: 20px;
}
.mobile_menu .dropdown .mini_icon {
    margin-right: 10px;
}
.mobile_menu .dropdown:hover .dropdown-mobile-content {
    display: block;
}
.mobile_menu .dropdown-mobile-content {
    display: none;
}
.mobile_menu .dropdown-mobile-content ul {
    list-style: none;
}
.mobile_menu .dropdown-mobile-content ul li a {
    display: flex;
    color: #008685;
    text-decoration: none;
    font-weight: bold;
    line-height: 38px;
    padding-left: 16px;
    font-size: 14px;
}
.mobile_menu .dropdown-mobile-content ul li span {
    display: flex;
    color: #008685;
    text-decoration: none;
    font-weight: bold;
    line-height: 38px;
    padding-left: 16px;
    font-size: 14px;
}
.mobile_menu .insider_btn {
    font-size: 18px;
}

.poper {
    background-color: white;
    max-width: 636px;
    width: 80%;
    padding: 32px;
    margin: auto;
    border-radius: 3px;
    box-shadow: 0 8px 16px 0 #00000014;
    height: auto;
    max-height: 62vh;
    overflow: auto;
    color: #4f636d;
}
.poper_head {
    display: flex;
    margin-bottom: 25px;
}
.poper_input {
    padding-left: 32px;
    margin-bottom: 32px;
}
.poper_input label {
    display: block;
    color: #050607;
    padding: 0 0 4px 16px;
    color: #4e636d;
    font-weight: bold;
}
.poper_input label.checkboxlabel {
    padding-left: 35px;
}
.poper_input .req {
    font-style: italic;
    margin-top: 4px;
    display: block;
    padding-left: 15px;
    width: fit-content;
    font-size: 14px;
}
.poper_body > p {
    padding-bottom: 16px;
}
.poper_body > ul {
    margin-left: 30px;
}
.poper_actions {
    display: flex;
    margin: 22px 0;
}

.grey {
    color: #2e3940;
}
.poper_input input[type="text"],
.poper_input textarea {
    width: 76%;
    max-width: calc(480px - 38px);
    border: 1px solid #93aab2;
    border-radius: 3px;
    font-size: 16px;
    padding: 13px 18px;
}
.poper_input textarea {
    min-height: 150px;
    outline: none;
    resize: none;
    display: block;
}
.poper_input input[type="text"]::placeholder,
.poper_input textarea::placeholder {
    color: #93aab2;
}
.poper_sub {
    color: #4e636d;
    padding: 0 0 4px 15px;
    display: block;
    max-width: 800px;
}
.poper_legend {
    display: inline-block;
    margin-left: 8px;
}
.poper_legend.red {
    color: #e02020;
}
.poper_btns {
    display: flex;
    justify-content: center;
}

.question_box {
    background-color: #f8fafb;
    padding: 24px;
    border-left: solid 4px rgba(0, 135, 183, 1);
    border-radius: 3px;
    margin-bottom: 38px;
    flex-wrap: wrap;
}
.question_box .title {
    color: #4e636d;
    font-weight: bold;
    font-size: 20px;
}
.question_box .sub_title {
    color: #4e636d;
    margin-top: 6px;
}
.question_box label {
    display: block;
    color: #4e636d;
    font-weight: bold;
}
.question_box input {
    width: 76%;
    max-width: 480px;
    border: solid 1px #93aab2;
    border-radius: 3px;
    font-size: 16px;
    padding: 13px 18px;
    margin-top: 16px;
}
.question_box input::placeholder {
    color: #93aab2;
    font-size: 16px;
}
.question_box .req {
    font-style: italic;
    margin-top: 4px;
    display: block;
    width: fit-content;
    color: #4e636d;
}
.question_box .req.red {
    font-style: normal;
    color: #e02020;
}
.question_box .input-invalid {
    border-color: #e02020;
}
.cat {
    border: solid 1px #e98b3a;
    margin-top: 16px;
    border-radius: 3px;
    display: flex;
    background-color: white;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.question_btns {
    display: flex;
    justify-content: flex-end;
}
.question_image {
    width: 350px;
    height: 250px;
    background-color: white;
    margin-left: auto;
    text-align: center;
    overflow: hidden;
    border: 1px solid #93aab2;
    border-radius: 3px;
}
.question_image img {
    height: 250px;
}
.question_box input.error {
    border: 1px solid #e02020;
}
.question_box .validation-error {
    color: #e02020;
    display: block;
    width: fit-content;
    margin-top: 4px;
}

.dropdown_field {
    position: relative;
    width: 76%;
    max-width: 480px;
}
.lrg {
    width: 100%;
    max-width: 497px;
}
.dropper {
    border: solid 1px #008685;
    border-radius: 3px;
    font-size: 16px;
    padding: 10px 18px;
    margin-top: 16px;
    display: flex;
    background-color: white;
    line-height: 28px;
}
.dropdown_field ul li {
    line-height: 38px;
    padding-left: 16px;
}
.dropper svg {
    color: #008685;
    margin-left: auto;
}
.key {
    font-size: 20px;
    border-right: 1px solid #008685;
    min-width: 32px;
    text-align: center;
}
.desc {
    padding: 5px 8px;
    line-height: 18px;
}

/* .profile { width: 40% !important; } */
.profile h2 {
    margin-bottom: 32px;
}
.profile p {
    display: flex;
    width: 100% !important;
    margin-bottom: 20px;
}

.amigos {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}
.amigos p {
    width: 30%;
}
/*.cat:hover{background-color: #ffd4af;cursor:pointer;}*/
.cat:hover {
    box-shadow: 0px 0px 0px 5px #e98b3a;
}

/* .cat:hover img{width:197px;height:132px;} */
.cat h3 {
    color: #4e636d;
    font-size: 16px;
    margin-bottom: 4px;
}
.catBox {
    padding: 16px;
}

/* .closer{border: solid 3px #4e636d;color: #4e636d;border-radius: 3px;height: 25px;width: 26px;text-align: center;padding-top: 1px;} */
.closer {
    margin-left: auto;
    cursor: pointer;
    color: #4e636d;
}
.menu_closer {
    border: solid 3px #008685;
    color: #008685;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    text-align: center;
    padding-top: 1px;
    margin-left: auto;
    cursor: pointer;
}
.mobile_menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f1f6f6;
    height: 100%;
    z-index: 9001;
    padding: 90px 0 0 0;
    color: #008685;
}

:disabled {
    opacity: 0.3;
    cursor: default;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 135, 183, 0.5);
    z-index: 9020;
    display: flex;
}
.choosen {
    box-shadow: 0px 0px 0px 5px #e98b3a;
}
.highlight {
    background-color: #ffd4af !important;
}
.information {
    color: #4f636d;
    display: flex;
    margin-top: 28px;
}
.information_icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
}
.information p {
    width: 90%;
    line-height: 32px;
}
.information a {
    color: #00a79d;
    text-decoration: none;
}

/*radio*/
.containerRadio {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: normal !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.periodicity .containerRadio {
    display: inline-block;
}
.containerRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.containerRadio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 50%;
    border: solid 1px #4f636d;
}
.containerRadio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.containerRadio input:checked ~ .checkmark:after {
    display: block;
}
.containerRadio .checkmark:after {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #4f636d;
    text-align: center;
    color: white;
    top: 5px;
    left: 5px;
}

/*checkmark*/
.container {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: normal !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid rgba(0, 135, 183, 1);
}
.checkmark:after {
    content: "\2713";
    position: absolute;
    display: none;
    font-size: 14px;
}
.container input:checked ~ .checkmark:after {
    display: block;
}
.container .checkmark:after {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    background: rgba(0, 135, 183, 1);
    text-align: center;
    color: white;
}
.container input:indeterminate ~ .checkmark {
    display: block;
    background: #85bdbe;
}
.container.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.noBook {
    padding: 40px;
    background-color: #f8fafb;
    border-top: solid 4px #e98b3a;
    margin: 0 40px;
}
.tree_box {
    padding: 32px;
    margin-bottom: 32px;
    border-top: solid 4px #00a79d;
    background-color: #f8fafb;
    height: calc(80vh - 197px);
    max-height: calc(80vh - 197px);
    overflow: auto;
}
.deni-react-treeview-container {
    width: 100%;
}
.deni-react-treeview-item-container {
    background-color: #f8fafb;
    padding: 7px 0;
    border-bottom: solid 0.5px #e2ebee !important;
    margin: 0 !important;
}
.deni-react-treeview-item-container:hover {
    background-color: #f8fafb !important;
}

.hamburger {
    display: none;
}

.calc_box {
    padding: 40px 64px;
    margin-bottom: 48px;
    display: inline-block;
    width: calc(100% - 128px);
}

.result_box {
    padding-bottom: 32px;
    margin-bottom: 48px;
}
.result_box h2 {
    font-size: 28px;
    padding: 40px 0 24px 32px;
}
.result_box h3 {
    font-size: 24px;
    padding-bottom: 16px;
}
.result_top {
    border-top: solid 4px #ea8c37;
    background-color: #f8fafb;
    padding: 12px 16px;
    margin: 0px 64px 24px 64px;
}
.result_top h3 {
    margin-bottom: 12px;
    font-size: 22px;
}

.result_group {
    display: flex;
    flex-wrap: wrap;
}
.result_bottom {
    border-top: solid 4px #4f636d;
    background-color: #f8fafb;
    padding: 12px 16px 0;
    margin: 0px 64px 24px 64px;
}
.result_input {
    border: solid 1px #93aab2;
    border-radius: 3px;
    padding: 10px 20px;
    width: 44%;
    background-color: white;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-around;
}
.result_params {
    display: flex;
    color: #4e636d;
    justify-content: center;
}
.result_params p {
    text-align: center;
    justify-content: space-between;
    width: 20%;
    margin-bottom: 10px;
}
.result_params p strong {
    display: block;
}

.result_params_long {
    display: flex;
    color: #4e636d;
    margin-bottom: 18px;
    transition: height 1s;
    overflow: hidden;
}
.result_params_long p {
    margin-bottom: 10px;
}
.result_params_long p strong {
    display: block;
}

.result_btns {
    display: flex;
    justify-content: flex-end;
    margin: 0px 64px;
}

.table_result_row {
    display: flex;
}
.table_result_row p {
    padding: 15px;
    padding: 15px;
    min-width: 155px;
    text-align: center;
    border-left: solid 1px #008685;
    border-bottom: solid 1px #e7e7e7;
}
.result_header {
    width: 14.28%;
    background-color: #85bdbe;
    color: white;
    min-width: 155px;
    text-align: center;
    border-left: solid 1px white !important;
    border-bottom: solid 3px white !important;
}
.result_cell {
    width: 14.28%;
    background-color: #008584;
    color: white;
    min-width: 155px;
    text-align: center;
    border-left: solid 1px white !important;
    border-bottom: solid 1px white !important;
}

.texter {
    font-size: 18px;
    font-weight: bold;
    color: #4f636d;
    line-height: 42px;
}
.number {
    color: #ea8c37;
    font-size: 28px;
    font-weight: bold;
    margin-left: auto;
}
.norm {
    margin-left: 15px;
    margin-bottom: 20px;
}

.result-block {
    width: 100%;
}
.result-block-venster {
    width: 95%;
    flex: 50%;
}
.result-input {
    border: 1px solid #93aab2;
    border-radius: 3px;
    padding: 10px 20px;
    width: 44%;
    background-color: white;
    display: flex;
    margin-bottom: 5px;
    width: 90%;
}
.result-block-venster > .result-input {
    min-width: 450px;
}

.zip_dropdown {
    background-color: white;
    border: solid 1px #008685;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 120px;
    left: 25px;
    width: 320px;
    max-height: 259px;
    height: auto;
    overflow: auto;
}
.zip_dropdown p {
    padding: 10px 20px;
    border-bottom: solid 1px #008685;
}
.zip_dropdown p:hover {
    background-color: #ffd4af;
    cursor: pointer;
}
.zip_dropdown p:last-child {
    border-bottom: none;
}

.mobile_menu {
    display: none;
}

/* Other tweaks */
.poper ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.poper ::-webkit-scrollbar-thumb {
    background: #93aab2;
    border-radius: 8px;
}
.poper ::-webkit-scrollbar-thumb:hover {
    background: #4f636d;
}

/* Notifications */
.notifications-container {
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 200;
}
.notification {
    margin-bottom: 20px;
    padding: 24px;
    width: 500px;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
}
.notification .notification-header {
    display: flex;
    margin-bottom: 10px;
}
.notification .notification-header h2 {
    margin: 0px;
    line-height: 24px;
    font-size: 20px;
}
.notification .notification-header h2 > svg {
    vertical-align: top;
    height: 22px;
    width: 22px;
}
.notification .notification-content {
    font-size: 16px;
    line-height: 21px;
    color: #4f636d;
    margin-right: 50px;
}

.notification.question {
    border-top: 4px #93aab2 solid;
    background: #f8fafb;
}
.notification.question .notification-header h2 {
    color: #93aab2;
}

.notification.info {
    border-top: 4px #0083e6 solid;
    background: #dbf0ff;
}
.notification.info .notification-header h2 {
    color: #0083e6;
}

.notification.warning {
    border-top: 4px #f7b500 solid;
    background: #fff4d6;
}
.notification.warning .notification-header h2 {
    color: #f7b500;
}

.notification.error {
    border-top: 4px #e02020 solid;
    background: #ffd8d8;
}
.notification.error .notification-header h2 {
    color: #e02020;
}

.notification.success {
    border-top: 4px #469430 solid;
    background: #eaffe5;
}
.notification.success .notification-header h2 {
    color: #469430;
}

/*Booklet*/
.bookletTop {
    display: flex;
    padding-top: 37px;
}
.bookletLang {
    display: flex;
    min-width: 400px;
    margin-left: 40px;
    margin-bottom: 50px;
}
.bookletNav {
    display: flex;
    margin-left: auto;
    margin-right: 25px;
    padding-top: 30px;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.tree_box_header {
    display: flex;
    padding-bottom: 32px;
    margin: 0 32px;
}

/* Components used by Maintenance Booklet */
div.btn_sec {
    height: 38px;
    line-height: 38px;
    vertical-align: middle;
}
div.btn_sec > svg {
    margin: auto 5px auto 0px;
}
div.btn_sec > svg:last-child {
    margin: auto 5px;
}
div.dropdown-sec {
    width: 100%;
    display: none;
    position: absolute;
    z-index: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
}
div.dropdown-sec ul {
    list-style: none;
    border: 1px solid rgba(0, 135, 183, 1);
    border-radius: 3px;
    background-color: white;
    margin-top: 5px;
}
div.dropdown-sec ul li {
    border-bottom: 1px solid rgba(0, 135, 183, 1);
    color: rgba(0, 135, 183, 1);
}
div.dropdown-sec ul li:hover {
    background-color: rgba(0, 135, 183, 1);
    color: #ffffff;
}
div.dropdown-sec ul li:last-child {
    border-bottom: none;
}
div.dropdown-sec ul li p {
    display: flex;
    text-decoration: none;
    line-height: 38px;
    padding-left: 20px;
    font-size: 14px;
}
div.dropdown-sec ul li p svg {
    margin-right: 5px;
    margin-top: 8px;
}
.dropdown:hover div.dropdown-sec {
    display: block;
}

.custom-dropdown {
    position: relative;
    width: 100%;
    max-width: 480px;
}
.custom-dropdown ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.custom-dropdown ::-webkit-scrollbar-thumb {
    background: #93aab2;
    border-radius: 3px;
}
.custom-dropdown ::-webkit-scrollbar-thumb:hover {
    background: #4f636d;
}
.custom-dropdown .req {
    font-style: italic;
    margin-top: 4px;
    display: block;
    padding-left: 16px;
    width: fit-content;
}
.custom-dropdown > label {
    font-weight: bold;
    color: #4f636d;
    padding: 0 0 4px 16px;
    display: block;
}
.custom-dropdown > .dropdown-input {
    border: solid 1px #93aab2;
    border-radius: 3px;
    font-size: 16px;
    padding: 10px 18px;
    display: flex;
    background-color: white;
    line-height: 28px;
    color: #93aab2;
}
.custom-dropdown > .dropdown-input.multiselect {
    font-size: 14px;
}
.custom-dropdown > .dropdown-input.multiselect > span {
    display: flex;
    flex-wrap: wrap;
}
.custom-dropdown > .dropdown-input.multiselect > svg {
    min-width: 30px;
}
.custom-dropdown > .dropdown-input.multiselect > span > span:not(#placeholder) {
    background-color: #93aab2;
    color: #ffffff;
    padding: 0 8px;
    border-radius: 3px;
    margin: 3px 8px 3px 0;
    display: flex;
}
.custom-dropdown > .dropdown-input.multiselect > span > span#placeholder {
    color: #93aab2;
}
.custom-dropdown > .dropdown-input.multiselect > span > span > svg {
    margin: 6px 0px 0px 8px;
    font-size: 15px !important;
    cursor: pointer;
}
.custom-dropdown > .dropdown-input > svg {
    color: #4f636d;
    margin-left: auto;
}
.custom-dropdown > .dropdown-content {
    display: none;
    width: calc(100% - 2px);
    margin-top: 1px;
    max-height: 300px;
    border: 1px solid #93aab2;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    right: 0px;
    z-index: 5;
}
.custom-dropdown > .dropdown-content > ul > li {
    line-height: 38px;
    padding-left: 16px;
    border-bottom: 1px solid #93aab2;
    white-space: nowrap;
    overflow-x: hidden;
}
.custom-dropdown > .dropdown-content > ul > li:last-child {
    border-bottom: none;
}
.custom-dropdown > .dropdown-content > ul > li:hover {
    color: #ffffff;
    background-color: #93aab2;
}
.custom-dropdown > span.remark {
    color: #2e3940;
    font-size: 14px;
    font-style: italic;
    margin-left: 16px;
}

/* View Maintenance Booklet */
#pdf-preview {
    box-shadow: 0px 5px 16px 4px #cecece;
}
#pdf-preview ::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}
#pdf-preview ::-webkit-scrollbar-thumb {
    background: #1b1d1d;
}
#pdf-preview ::-webkit-scrollbar-thumb:hover {
    background: #838383;
}
#pdf-preview-toolbar {
    background-image: linear-gradient(hsla(0, 0%, 32%, 0.99), hsla(0, 0%, 27%, 0.95));
    height: 32px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    color: white;
    display: flex;
    padding-left: 50px;
    line-height: 32px;
    font-size: 12px;
}
#pdf-preview-toolbar button {
    background: transparent;
    border: none;
    color: #ffffff;
    cursor: pointer;
}
#pdf-preview-toolbar button:hover {
    color: #cccccc;
}
#pdf-preview-toolbar input[type="number"] {
    background: transparent;
    border: 1px solid #000000;
    text-align: right;
    color: white;
    width: 40px;
    padding: 2px;
    font-size: 12px;
}
#pdf-preview-toolbar input[type="number"]::-webkit-inner-spin-button,
#pdf-preview-toolbar input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
#pdf-preview-toolbar .divider {
    margin: 0px 25px;
    border-left: 1px solid black;
}
#pdf-preview-loading {
    height: 700px;
    line-height: 700px;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    margin: auto;
    color: white;
}
.pdf-preview-page canvas {
    margin: auto;
    box-shadow: 0 0 10px 5px black;
}

.toggle-button {
    display: flex;
    margin-top: 40px;
}
.linkBox > .toggle-button {
    float: right;
}
.toggle-button > .btn_sec {
    border-radius: 0px;
}
.toggle-button > .btn_sec:first-of-type {
    border-radius: 20px 0px 0px 20px;
}
.toggle-button > .btn_sec:last-of-type {
    border-radius: 0px 20px 20px 0px;
}
.toggle-button > .btn_sec {
    border-radius: 0px;
}
.toggle-button > .btn_sec > svg {
    margin-right: 6px;
}
.toggle-button > .btn_sec.active {
    color: #ffffff;
    background-color: rgba(0, 135, 183, 1);
}

/* Edit Maintenance Booklet */
.action-button-dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 0px 5px;
    height: 25px;
    color: #4f636d;
}
.action-button-dropdown:hover {
    color: #000000;
}
.action-button {
    font-size: 12px;
    font-weight: bold;
    display: flex;
    line-height: 25px;
    cursor: pointer;
    color: #4f636d;
}
.action-content {
    display: none;
    position: absolute;
    background-color: white;
    z-index: 1;
    border: 1px solid #4f636d;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    /* right: 0px; */
}
.action-content ul {
    list-style: none;
}
.action-content ul li {
    border-bottom: 1px solid #4f636d;
    color: #4f636d;
}
.action-content ul li:hover {
    background-color: #4f636d;
    color: white;
    cursor: pointer;
}
.action-content ul li:last-child {
    border-bottom: none;
}
.action-content ul li p {
    display: flex;
    line-height: 18px;
    padding: 11px 16px 13px 16px;
    font-size: 12px;
    width: max-content;
}
.action-content ul li p svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
}
.action-button-dropdown:hover .action-content {
    display: block;
}
.action-button svg {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
}
.action-button.imported svg {
    height: 28px;
    width: 28px;
    stroke-width: 20px;
    stroke: #4f636d;
    fill: #4f636d;
    margin-top: -2px;
}
.action-button:hover.imported svg {
    stroke: #93aab2;
    fill: #93aab2;
}
.tree-modal {
    background-color: #ffffff;
    max-width: 968px;
    margin: auto;
    max-height: 80vh;
    border-radius: 3px;
    box-shadow: 0 8px 16px 0 #00000014;
    padding: 32px;
    width: 80%;
    overflow: auto;
}

.poper_content_title {
    padding: 13px 0;
}
.poper_content {
    max-height: calc(62vh - 130px);
    overflow: auto;
    margin-bottom: 32px;
}

.periodicity-input {
    display: flex;
    margin: 10px 0 10px 31px;
}
.periodicity-input.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.numeric-input {
    display: flex;
    width: 136px;
}
.numeric-input input[type="number"] {
    width: calc(136px - 50px - 36px);
    border: 1px solid #93aab2;
    border-radius: 3px 0px 0px 3px;
    border-right: none;
    font-size: 16px;
    padding: 13px 18px;
    color: #4f636d;
}
.numeric-input input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
.numeric-input input[type="number"]::-webkit-inner-spin-button,
.numeric-input input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
.numeric-input .controls {
    display: grid;
}
.numeric-input .controls button {
    width: 50px;
    height: 25px;
    background-color: transparent;
    border: 1px solid #93aab2;
    color: #4f636d;
    cursor: pointer;
}
.numeric-input .controls button svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    line-height: 25px;
}
.numeric-input .controls button:hover {
    background-color: #93aab2;
}
.numeric-input .controls button:first-child {
    border-top-right-radius: 3px;
}
.numeric-input .controls button:last-child {
    border-top: none;
    border-bottom-right-radius: 3px;
}

.booklet-label {
    height: 20px;
    line-height: 16px;
    border-radius: 3px;
    color: #ffffff;
    font-size: 14px;
    padding: 2px 6px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 100;
    margin-left: 12px;
}
.booklet-label > svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    vertical-align: middle;
}
.booklet-label > span {
    padding-right: 5px;
    line-height: 16px;
    vertical-align: middle;
}
.booklet-label.added {
    background-color: rgba(0, 135, 183, 1);
}
.booklet-label.modified {
    background-color: #c45b00;
    padding-left: 10px;
}
.booklet-label.procedure {
    margin: 12px 0 0 0;
}

/* Tree */
.tree-item {
    border-bottom: 1px solid #cecece;
    padding: 15px 0px 15px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.tree-item:hover {
    background-color: rgba(0, 135, 183, 0.25);
}
.tree-item-expander {
    min-width: 24px;
    width: 24px;
    display: inline-block;
}
.tree-item-expander:hover {
    cursor: pointer;
    color: #999999;
}
.tree-item-checkbox {
    min-width: 29px;
    width: 29px;
    display: inline-block;
}
.tree-item-value p,
.tree-item-value > div.group {
    max-width: 800px;
}
.tree-item-value span {
    word-break: break-word;
}
.tree-item-value .group {
    display: flex;
    margin-top: 15px;
}
.tree-item-value .group:first-of-type {
    margin-top: 20px;
}
.tree-item-value .group > span:first-of-type {
    font-weight: bold;
    min-width: 120px;
}
.tree-item-value .group span.periodicity {
    color: #00a79d;
}
.tree-item-value .group span.remarks {
    color: #f7931d;
}
.tree-item-value .group span.sources {
    color: #00a79d;
}
.tree-item-actions {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
}
.tree-item-actions button {
    border: none;
    background-color: transparent;
    padding: 0px 5px;
    height: 25px;
    cursor: pointer;
    color: #4f636d;
}
.tree-item-actions button:hover {
    color: #000000;
}
.tree-item-actions button svg {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
}
.tree-item-actions button span {
    line-height: 20px;
    font-size: 12px;
    font-weight: bold;
}

span.highlighted-text {
    background-color: #ffff00;
}

/* Header */
.header-menu > li > a > img {
    width: 20px;
    height: 20px;
    margin: auto 15px auto 0px;
    display: inline-block;
}
.header-menu > li:hover > a > img {
    color: white;
}

/* Max. 800px content */
.question_box p,
.descBox p {
    max-width: 800px;
}

/* Feedback */
.feedback-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    padding: 10px;
    border-radius: 40px 40px 40px 40px;
    background-color: #00a79d;
    color: #ffffff;
    font-size: 16px;
    width: 40px;
    display: flex;
    height: 40px;
    cursor: pointer;
    opacity: 0.3;
    z-index: 2;
}

.feedback-button:hover {
    background-color: #008685;
    opacity: 1;
}
.feedback-button.active {
    border-radius: 0px 40px 40px 40px;
    opacity: 1;
}
.feedback-button > svg {
    margin: auto;
}
.feedback-modal {
    background-color: #ffffff;
    width: 400px;
    max-width: calc(100vw - 95px);
    padding: 32px;
    margin: auto;
    border-radius: 3px;
    box-shadow: 0 8px 16px 0 #00000014;
    height: auto;
    max-height: calc(90vh - 95px);
    overflow: auto;
    color: #4f636d;
    position: fixed;
    right: 90px;
    bottom: 90px;
    z-index: 20;
}
.feedback-header {
    display: flex;
    margin-bottom: 25px;
}
.feedback-content {
    max-height: calc(90vh - 210px);
    overflow: auto;
    padding-right: 20px;
}
.feedback-input {
    margin-bottom: 32px;
}
.feedback-input label {
    display: block;
    padding: 0 0 4px 16px;
    color: #4e636d;
    font-weight: bold;
}
.feedback-input .req {
    font-style: italic;
    margin-top: 4px;
    display: block;
    padding-left: 16px;
    width: fit-content;
    font-size: 10px;
}
.feedback-input input[type="text"],
.feedback-input textarea {
    width: 100%;
    max-width: calc(100% - 38px);
    border: 1px solid #93aab2;
    border-radius: 3px;
    font-size: 16px;
    padding: 13px 18px;
}
.feedback-input textarea {
    min-height: 150px;
    outline: none;
    resize: none;
    display: block;
}
.feedback-input > .containerRadio {
    padding-left: 32px;
}
.feedback-footer {
    display: flex;
    justify-content: center;
}

.stepper {
    margin-bottom: 40px;
}
.step_box {
    position: relative;
    background-color: #00a79d;
    border: 4px solid white;
    box-shadow: 0 0 0 0 red;
    width: 33%;
}

.step_inner {
    display: flex;
    padding: 15px;
}
.step_inner span {
    color: white;
    font-weight: bold;
    line-height: 35px;
    font-size: 20px;
}
.step_circle {
    padding: 10px 12px;
    background-color: white;
    color: #00a79d;
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    margin-right: 20px;
    border-radius: 50%;
    width: 16px;
    font-weight: bold;
}
.step_box li > * > span:last-of-type {
    text-overflow: ellipsis;
    overflow: hidden;
}
/*descktop*/
@media (max-width: 1680px) {
    .breadcrumb_wrapper {
        padding: 0 20px;
        margin-left: auto;
        left: 0;
    }
    .content,
    #viewer-header {
        padding: 0 20px;
    }
}

.content {
    padding: 0 100px;
    min-height: calc(100vh - 310px);
}

/*tablet*/
@media (min-width: 989px) and (max-width: 1200px) {
    .header {
        padding: 18px 20px;
    }
    .insider {
        width: 80%;
        padding: 12px 20px;
    }
    .insider_title {
        font-size: 24px;
        width: 20%;
        white-space: nowrap;
    }
    .insider_btn {
        font-size: 12px;
        margin-right: 10px;
    }
    .content {
        padding: 0 20px;
    }
    .content_header h1 {
        width: 45%;
    }
    .act_text {
        display: none;
    }

    .home_img {
        height: 210px;
    }
    .home_info {
        width: 264px;
    }
    .hamburger {
        display: none;
    }
    .login {
        padding: 0 20px;
    }
    .mobile_menu {
        display: none;
    }

    .profile p {
        display: block;
        width: 100% !important;
    }
    .profile p span {
        display: block;
    }
}

/*mobile*/
@media (min-width: 320px) and (max-width: 990px) {
    h1 {
        margin: 0 20px;
    }

    .login_btns {
        display: none;
    }
    .mobile_menu {
        display: block;
    }
    .login_right {
        display: none;
    }
    .login_wrapper {
        display: inherit;
    }
    .login_top img {
        margin-top: 90px;
    }

    .home_wrapper {
        padding: 0 20px;
    }

    .hamburger {
        display: block;
    }

    .top_header {
        display: none;
    }
    .top_header_btns {
        padding-top: 6px;
    }
    .top_header_btns .dropdown {
        display: none !important;
    }
    .sub_header_btns {
        display: none;
    }
    .breadcrumb_wrapper {
        top: 77px;
    }

    .content {
        padding: 0;
        min-height: calc(100vh - 310px);
    }
    .content_info {
        display: block;
    }
    .descBox {
        width: auto;
        margin: 0 20px;
        padding: 0;
    }
    .dateBox {
        margin: 20px 40px 0;
        text-align: left;
        width: auto;
    }

    .calc_box {
        padding: 40px 20px;
        width: 90%;
        display: block;
        margin: 0 auto;
    }

    .login {
        padding: 0 10px;
    }
    .login_top {
        width: inherit;
    }
    .login_top img {
        width: 70%;
    }

    .login_content {
        padding: 0 10px;
    }
    .login_input input[type="text"],
    .login_input input[type="password"] {
        width: 94%;
    }

    .result_top,
    .result_bottom {
        margin: 0 24px 24px;
    }
    .result_input {
        width: 86%;
        justify-content: space-between;
    }
    .result_params {
        flex-wrap: wrap;
    }
    .result_params p {
        width: 100%;
        margin-bottom: 10px;
        text-align: left;
    }
    .result_btns {
        margin: 0px 20px;
    }
    .texter {
        font-size: 16px;
    }

    .table_head .dt,
    .table_head .act {
        display: none;
    }
    .table_line {
        display: inherit;
        line-height: 24px;
        padding-bottom: 20px;
    }
    .table_line .prj_name {
        display: block;
        padding: 20px 0px 20px 20px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .table_line .dt {
        display: block;
        padding-left: 20px;
        width: calc(100% - 20px);
    }
    .table_line .dt strong {
        display: inline-block;
    }

    .act {
        position: absolute;
        top: 18px;
        right: 20px;
    }
    .act_text {
        display: none;
    }

    .cat {
        display: inherit;
        flex-direction: row;
    }

    .amigos {
        display: block;
    }
    .amigos p {
        width: unset;
    }
    .profile {
        margin: 0 auto 48px;
        display: block !important;
        width: 86% !important;
    }
    .profile p {
        display: block;
        width: 100% !important;
    }
    .profile p span {
        display: block;
    }

    /*booklet*/
    .bookletTop {
        display: block;
    }
    .bookletLang {
        width: auto;
        margin: 0 10px 30px;
        min-width: initial;
    }
    .bookletNav {
        display: block;
        padding-top: 10px;
        margin: 0 10px 20px;
    }
    .bookletNav button {
        margin-bottom: 10px;
        width: 100%;
        max-width: 404px;
    }
    .noBook {
        margin: 0 20px;
    }
    .tree_box {
        padding: 32px 10px;
        font-size: 14px;
    }
    .tree_box_header {
        display: block;
        margin: 0 10px;
    }
    .tree_box_header .search_box {
        margin-left: 0;
    }
    .tree_box_header button {
        margin-bottom: 10px;
    }
    .tree-modal {
        width: 100vw;
        padding: 32px 10px;
        margin: 0;
        max-height: 100%;
    }
    .tree-item-actions {
        min-width: 100px;
    }
    .tree-item-actions span {
        display: none;
    }
    .poper {
        padding: 32px 10px;
        width: 100%;
    }

    .step_box li > * > span:last-of-type {
        display: none;
    }
}

.stepper {
    overflow: hidden;
}
.step_box {
    background-color: transparent;
    padding: 0;
    width: 100%;
    margin: 1em auto;
    border-radius: 0.25em;
    clear: both;
    border-bottom: none;
}

.step_box li {
    position: relative;
    padding: 0;
    margin: 4px 4px 4px 0;
    width: calc(33% - 4px);
    float: left;
    text-align: center;
    cursor: pointer;
}

.step_box li > * {
    position: relative;
    padding: 1em 0.8em 0.8em 2.5em;
    color: white;
    font-weight: bold;
    background-color: #85bdbe;
    border-color: #85bdbe;
}
.step_box li > * > span.nmbr {
    color: #85bdbe;
}

.step_box li.completed > * {
    color: #fff !important;
    background-color: #005b55 !important;
    border-color: #005b55 !important;
    border-bottom: none !important;
}
.step_box li.completed > * > span.nmbr {
    color: #005b55 !important;
}

.step_box li.active > * {
    color: #fff !important;
    background-color: #00a79d !important;
    border-color: #00a79d !important;
    border-bottom: none !important;
}
.step_box li.active > * > span.nmbr {
    color: #00a79d !important;
}

.step_box a:hover {
    color: #fff;
    background-color: #2c3f4c;
    border-color: #2c3f4c;
}

span.nmbr {
    display: inline-block;
    /* padding: 10px 0 0 0; */
    background: #ffffff;
    width: 35px;
    line-height: 35px;
    height: 35px;
    /* margin: auto; */
    border-radius: 50%;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 135, 183, 1);
    margin-right: 20px;
    text-align: center;
}

.step_box li.active a {
    padding-top: 15px;
}

.step_box li::after,
.step_box li > *::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 100%;
    height: 0;
    width: 0;
    border: 31px solid transparent;
    border-right-width: 0;
}
.step_box li::after {
    z-index: 1;
    -webkit-transform: translateX(4px);
    -moz-transform: translateX(4px);
    -ms-transform: translateX(4px);
    -o-transform: translateX(4px);
    transform: translateX(4px);
    border-left-color: #fff;
    margin: 0;
}
.step_box li > *::after {
    z-index: 2;
    border-left-color: inherit;
}

.step_box li:last-child {
    margin-right: 0;
}

.step_box li:last-child:after {
    content: "";
    border: none;
}
.step_box li:last-child p:after {
    content: "";
    border: none;
}

.disabled_button {
    border: none;
    font-family: "robotomedium";
    border-radius: 20px;
    background-color: #93aab2;
    color: white;
    line-height: 40px;
    font-size: 16px;
    display: flex;
    padding: 0 20px;
    font-weight: normal;
    height: 40px;
    cursor: pointer;
    align-items: center;
}

.disabled_button svg {
    margin-right: 5px;
}
